'use client';
import * as React from 'react';
import { Text, WarningIllustration, Button, FlippingCoin } from '@dreamplan/ui';
import Link from 'next/link';

export default function NotFound() {
  let content = [];
  const [route, setRoute] = React.useState('');
  const [locale, setLang] = React.useState('');

  React.useEffect(() => {
    const currentRoute = window.location.pathname;
    const currentLang = currentRoute.substring(0, 3);
    setRoute(currentRoute);
    setLang(currentLang);
  }, []);

  if (locale === '/en') {
    content = [
      `Oops! Page ${route} was not Found`,
      'The page you are looking for doesn’t exist.',
      'In the meantime, why not check out some of our articles?',
    ];
  } else {
    content = [
      `Ups! Siden ${route} blev ikke fundet`,
      'Siden du leder efter findes ikke.',
      'I mellemtiden, hvorfor ikke tjekke nogle af vores artikler?',
    ];
  }

  return (
    <div className="flex flex-col items-center gap-y-5 px-5 py-16 text-center">
      <div className="max-sm:hidden">
        <WarningIllustration />
      </div>
      <div className="hidden max-sm:block">
        <FlippingCoin />
      </div>
      {content.map((text, index) => (
        <Text
          key={index}
          variant={index === 0 ? 'h2' : index === 1 ? 'h3' : 'paragraph'}
          className="max-w-screen-sm"
        >
          {text}
        </Text>
      ))}

      <Button variant={'primary'} className="mt-5 flex gap-x-10">
        <Link
          href={`${locale === '/en' ? '/en' : ''}/${locale === '/en' ? 'articles' : 'artikler'}`}
        >
          {locale === 'en' ? 'See articles' : 'Se artikler'}
        </Link>
      </Button>
    </div>
  );
}
