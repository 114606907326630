'use client';
import * as React from 'react';
import { twMerge } from '@dreamplan/utils';
import { VariantProps, cva } from 'class-variance-authority';
import { Slot, Slottable } from '../utils';

const button = cva(
  ['text-center inline-flex justify-center items-center rounded-full cursor-pointer'],
  {
    variants: {
      variant: {
        primary: 'bg-primary text-on-primary transition-colors hover:bg-primary-dark',
        secondary: ['bg-tertiary text-on-tertiary', 'hover:text-gray-250 transition-colors'],
        link: [
          'underline text-tertiary',
          'hover:text-gray-700 transition-colors',
          'disabled:text-gray-250',
        ],
        outlined: [
          'border border-tertiary text-tertiary bg-transparent ',
          ' hover:ring-1 hover:ring-tertiary transition-all',
        ],
        'outlined-inverted': [
          'border border-white text-white bg-transparent',
          'hover:ring-1 hover:ring-white transition-all',
        ],
        'outlined-stripped': [
          'border border-tertiary text-tertiary bg-transparent border-dashed',
          'transition-all hover:ring-tertiary hover:border-solid hover:border-tertiary',
        ],
        text: ['text-tertiary bg-transparent', 'hover:text-gray-400 disabled:text-gray-250'],
      },
      size: {
        small: 'py-1 px-2.5',
        large: 'py-2 px-6',
        full: 'py-3 w-full px-8',
        none: '', // caller will specify width, height and paddings
      },
    },
    compoundVariants: [
      {
        variant: ['primary', 'secondary'],
        class: 'disabled:text-gray-250 disabled:bg-gray-50',
      },
      {
        variant: ['outlined', 'outlined-inverted', 'outlined-stripped'],
        class: ['disabled:border-gray-100 disabled:text-gray-250'],
      },
    ],
    defaultVariants: {
      size: 'large',
      variant: 'primary',
    },
  },
);

type ButtonCustomProps = {
  asChild?: boolean;
  rightIcon?: React.ReactNode;
  leftIcon?: React.ReactNode;
};

type ButtonProps =
  // default button props
  React.ButtonHTMLAttributes<HTMLButtonElement> &
    // available button style variants
    VariantProps<typeof button> &
    // extra props
    ButtonCustomProps;

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {
    className,
    size,
    variant,
    asChild = false,
    children = 'Se mere',
    rightIcon,
    leftIcon,
    ...coreProps
  } = props;

  const varaintClass = button({ size, variant });
  const Component = asChild ? Slot : 'button';

  return (
    <Component className={twMerge(varaintClass, className)} ref={ref} {...coreProps}>
      {leftIcon != null && (
        <span className="-mt-1 mr-2 inline-flex items-center justify-center">{leftIcon}</span>
      )}
      <Slottable>{children}</Slottable>
      {rightIcon != null && <span className="ml-2">{rightIcon}</span>}
    </Component>
  );
});

Button.displayName = 'Button';
